import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BaseUrl } from '../BaseUrl'
import Footer from '../main-components/Footer'
import Header from '../main-components/Header'
import { addSaasclientsData, addWebsitecategoriesData, addWebsitedevelopmentData, addWebsiteprocessData, addWebsitesliderData } from '../Redux/SliceData/SliceData'
// import WebFrameBanner from './sub-components/WebFrame/WebFrameBanner'
import WebFrameMain from './sub-components/WebFrame/WebFrameMain'
import WebsiteBanner from './sub-components/WebFrame/WebsiteBanner'

const WebFrame = () => {
  const dispatch = useDispatch();
  useEffect(() => {

    ;(async () => {
      // websitedevelopment api
      const websitedevelopment = await fetch(`${BaseUrl}/websitedevelopment`);
      const websitedevelopmentJson = await websitedevelopment.json();
      dispatch(addWebsitedevelopmentData(websitedevelopmentJson.websitedevelopment));

      // websiteslider api
      const websiteslider = await fetch(`${BaseUrl}/websiteslider`);
      const websitesliderJson = await websiteslider.json();
      dispatch(addWebsitesliderData(websitesliderJson.websiteslider));

      // websitecategories api
      const websitecategories = await fetch(`${BaseUrl}/websitecategories`);
      const websitecategoriesJson = await websitecategories.json();
      dispatch(addWebsitecategoriesData(websitecategoriesJson.websitecategories));

      // websiteprocess api
      const websiteprocess = await fetch(`${BaseUrl}/websiteprocess`);
      const websiteprocessJson = await websiteprocess.json();
      dispatch(addWebsiteprocessData(websiteprocessJson.websiteprocess));

      // saasclients api
      const saasclients = await fetch(`${BaseUrl}/saasclients`);
      const saasclientsJson = await saasclients.json();
      dispatch(addSaasclientsData(saasclientsJson.saasclients));
      
    })();

  }, []);
  return (
    <>
    <section className='web-hero-area'>
    <Header/>
    </section>
    {/* <WebFrameBanner/> */}
    <WebsiteBanner/>
    <WebFrameMain/>
    <Footer/>
    </>
  )
}

export default WebFrame