import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper';
import Page1 from './Page1';

import 'swiper/swiper-bundle.min.css';
import './Pstyle.css'
import './CrmMega.css'

const ExpandableList = ({ heading, items, onClick, activeLink, handleLinkClick, isExpanded, handleClick }) => {
    const handleItemClick = (index) => {
        onClick(index);
        handleLinkClick(index);
    };

    return (
        <ul style={{ position: 'relative', alignSelf: 'flex-start' }}>
            <h4 style={{ justifyContent: 'space-between' }} className={`rectangle ${isExpanded ? 'expanded' : ''}`} onClick={handleClick}>
                {heading} <i style={{ color: '', marginRight: '4px' }} className={`fa-solid fa-angle-${isExpanded ? 'down' : 'right'}`}></i>
            </h4>
            {isExpanded && (
                <div style={{ position: 'relative', top: '100%', left: 0, flexDirection: 'column', display: 'flex' }}>
                    {items.map((item, index) => (
                        <li
                            className={`${activeLink === index ? 'underline active' : ''}`}
                            onClick={() => handleItemClick(index)}
                            key={index}
                        >
                            <a href="#">{item}</a>
                        </li>
                    ))}
                </div>
            )}
        </ul>
    );
};

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCube]);

const SliderTest = () => {

    const swiperRef = React.useRef(null);
    const [activeLine, setActiveLine] = useState(0);
    const [expandedMenuIndex, setExpandedMenuIndex] = useState(0);
    const [mainHeading, setMainHeading] = useState(0);

    const [ProjectData, setProjectData] = useState();
    const getProject = async () => {
        let responce = await fetch('http://localhost:500/getProject/6617ac137e947b94bdd09a7c')
        let data = await responce.json();
        setProjectData(data.project)
    }

    useEffect(() => {
        getProject();
    }, [])

    const handleLine = (link) => {
        setActiveLine(link);
    };
    const handleMenuClick = (index) => {
        setMainHeading(index);
        setExpandedMenuIndex(index === expandedMenuIndex ? null : index);
    };

    const goToSlide = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }
    };


    return (
        <>
            <div className="p-body mainCrmSlider" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div className='intro' >
                    <h4 className="rectangle intro-menu" style={{ display: 'flex', justifyContent: 'right', width: '105%' }}>
                        <div className="intro-btn wobble-hor-bottom"><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg></i>
                        </div>
                    </h4>

                    {ProjectData?.mainHeading.map((item, index) =>
                        <div>
                            <ExpandableList
                                heading={item}
                                items={ProjectData.subHeadings[index].map(item => item)}
                                onClick={goToSlide}
                                activeLink={activeLine}
                                handleLinkClick={handleLine}
                                isExpanded={expandedMenuIndex === index}
                                handleClick={() => handleMenuClick(index)}
                            />
                        </div>
                    )}

                </div>
                <Swiper
                    ref={swiperRef}
                    spaceBetween={50}
                    slidesPerView={6}
                    navigation={{ clickable: true }}
                    pagination={true}
                    scrollbar={{ draggable: true }}
                    effect="cube"
                    cubeEffect={{
                        shadow: false,
                        slideShadows: true,
                        shadowOffset: 20,
                        shadowScale: 0.94,
                    }}
                    onSlideChange={(swiper) => {
                        if (expandedMenuIndex === mainHeading) {
                            setActiveLine(swiper.activeIndex);
                        }
                    }}
                    id='p-swipper'
                >
                    {ProjectData?.subHeadings[mainHeading].map((item, index) =>
                        <SwiperSlide key={index}>
                            <Page1 index={index + 1} items={ProjectData?.pointsArr[mainHeading][index]} subHeadings={item} num={mainHeading} />
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </>
    );
};

export default SliderTest

