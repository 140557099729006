import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BaseUrl } from '../BaseUrl'
import Footer from '../main-components/Footer'
import Header from '../main-components/Header'
import { addSaascategoriesData, addSaasclientsData, addSaasprocessData, addSaasservicesData, addSaasslidersData, addSettingsData } from '../Redux/SliceData/SliceData'
// import SaasBanner from './sub-components/Saas/SaasBanner'
import SaasHero from './sub-components/Saas/SaasHero'
import SaasMain from './sub-components/Saas/SaasMain'

const Saas = () => {
  const dispatch = useDispatch();
  useEffect(() => {

    ;(async () => {
            // settings api
            const settings = await fetch(`${BaseUrl}/settings`);
            const settingsJson = await settings.json();
            dispatch(addSettingsData(settingsJson.settings));

      // saasservices api
      const saasservices = await fetch(`${BaseUrl}/saasservices`);
      const saasservicesJson = await saasservices.json();
      dispatch(addSaasservicesData(saasservicesJson.saasservices));

      // saassliders api
      const saassliders = await fetch(`${BaseUrl}/saassliders`);
      const saasslidersJson = await saassliders.json();
      dispatch(addSaasslidersData(saasslidersJson.saassliders));

      // saascategories api
      const saascategories = await fetch(`${BaseUrl}/saascategories`);
      const saascategoriesJson = await saascategories.json();
      dispatch(addSaascategoriesData(saascategoriesJson.saascategories));

      // saasclients api
      const saasclients = await fetch(`${BaseUrl}/saasclients`);
      const saasclientsJson = await saasclients.json();
      dispatch(addSaasclientsData(saasclientsJson.saasclients));

      // saasprocess api
      const saasprocess = await fetch(`${BaseUrl}/saasprocess`);
      const saasprocessJson = await saasprocess.json();
      dispatch(addSaasprocessData(saasprocessJson.saasprocess));
      
    })();

  }, []);
  return (
    <>
    <section className='web-hero-area'>
    <Header/>
    </section>
    <SaasHero/>
    {/* <SaasBanner/> */}
   
    <SaasMain/>
    <Footer/>
    </>
  )
}

export default Saas