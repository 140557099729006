import './App.css';
import RouterPage from './main-components/RouterPage';
// import React, { useState } from "react";
import React, { useEffect } from "react";
import ReactGa from 'react-ga';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-5M9Z4RM'
}
TagManager.initialize(tagManagerArgs)

function App() {
  // const [loading, setLoading] = useState(true);
  // const spinner = document.getElementById("spinner");
  // if (spinner) {
  //   setTimeout(() => {
  //     spinner.style.display = "none";
  //     setLoading(false);
  //   }, 1000);
  // }



  useEffect(() => {
    ReactGa.initialize('UA-243832973-2')

    // to report page view
    ReactGa.pageview(window.location.pathname + window.location.search)
  }, [])
  return (
    // !loading && (
    <div className="App">
      <RouterPage />
      <div className='whatsAppIcon'><a target="_blank" href="https://api.whatsapp.com/send?phone=+917603032300&text=Hello, I would like to know more about your IT services"><img src="assets\Images\whatsAppIcon.svg" alt="whatsapp" /></a></div>
    </div>
    //  )
  );
}

export default App;