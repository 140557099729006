import React from 'react';
import { Link } from 'react-router-dom';
// import Donut from './Donut';
import GridCarousel from './GridCarousel';
import VidBanner from './VidBanner';

const DigitalMain = () => {
    return (
        <>
            {/* <!-- tabs section starts --> */}
            {/* We know the pulse of digital marketing because we create digital product. */}

            {/* <!-- tabs section starts --> */}


            <GridCarousel />

            <section className="tab-sec mb-60 mt-80">
                <div className="container">
                    {/* <div className="main-title text-center">
                        <h2>Website Support We Offer</h2>

                    </div> */}
                    <div className="contact-text web-text digi-text my-auto text-center max-100 ps-0 main-title mb-60">
                        <div className="head text-center ">
                            <p className="first mx-auto">
                                We know the pulse of digital marketing because we create digital product.
                            </p>
                        </div>
                        <h2>Digital Strategic Services We Offer</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 px-0">
                            <div className="bg-gr web-men">
                                <nav>
                                    <div className="nav justify-content-around nav-tabs perNav" id="nav-tab" style={{ width: 'auto', gap: '10px' }} role="tablist">
                                        <button style={{ width: 'auto' }} className="nav-lin active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">SEO On-Page</button>
                                        <button style={{ width: 'auto' }} className="nav-lin" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">SEO Off-Page</button>
                                        <button style={{ width: 'auto' }} className="nav-lin" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Social Media Marketing</button>
                                        <button style={{ width: 'auto' }} className="nav-lin" id="nav-speed-tab" data-bs-toggle="tab" data-bs-target="#nav-speed" type="button" role="tab" aria-controls="nav-speed" aria-selected="false">Performance Marketing</button>
                                        <button style={{ width: 'auto' }} className="nav-lin" id="nav-App-tab" data-bs-toggle="tab" data-bs-target="#nav-App" type="button" role="tab" aria-controls="nav-App" aria-selected="false">Email Marketing</button>
                                        {/* <button style={{ width: 'auto' }} className="nav-lin" id="nav-Analytics-tab" data-bs-toggle="tab" data-bs-target="#nav-Analytics" type="button" role="tab" aria-controls="nav-Analytics" aria-selected="false">Analytics and Reporting</button> */}
                                        {/* <button className="nav-lin" id="nav-ams-tab" data-bs-toggle="tab" data-bs-target="#nav-ams" type="button" role="tab" aria-controls="nav-ams" aria-selected="false">AMS</button> */}
                                        <div className="animation start-home" id="marker"></div>
                                    </div>
                                </nav>
                            </div>
                            <div className="tab-content px-3" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Optimize your website's on-page elements for improved search engine visibility and user experience.</p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Keyword research and optimization.                                           </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Content optimization for search engines.                                               </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Meta tags and URL optimization.                                               </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Internal linking structure enhancement.                                                  </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Mobile-friendly optimization.                                                  </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Site speed optimization.                                   </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Schema markup implementation.                                  </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">User experience improvement strategies.                                        </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/OnPageSeo.png" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80 ">

                                                <p>Boost your website's authority and ranking through off-page SEO strategies and link building techniques.</p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">High-quality backlink acquisition.</span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text"> Guest blogging and content syndication.                                                  </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Social bookmarking and directory submissions.                                             </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Influencer outreach and partnerships.                                               </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Local SEO optimization.                                             </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Online reputation management.                                                </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Competitor analysis and benchmarking.                                       </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Link disavowal and penalty recovery.                                                  </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/OffPageSeo.jpg" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Engage and expand your audience through strategic social media campaigns and content promotion.</p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Social media strategy development.                                        </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Content creation and curation.                                                  </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Community management and engagement.                              </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Paid social advertising campaigns.                                                  </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Influencer collaborations and partnerships.                                                </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Social media analytics and reporting.                                      </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Brand reputation monitoring.                                                  </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text"> Social listening and trend analysis.                              </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/SocialMediaMarketing.png" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-speed" role="tabpanel" aria-labelledby="nav-speed-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Drive targeted traffic and conversions through data-driven performance marketing strategies.</p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Pay-per-click (PPC) advertising campaigns.                                            </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Display and retargeting ads.                                                </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Affiliate marketing programs.                                          </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Conversion rate optimization (CRO) strategies.                                    </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Landing page optimization.                                                </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Campaign tracking and analytics.                                       </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Budget allocation and ROI analysis.                                                  </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Continuous optimization for better results.                                                 </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/performanceMarketing.jpeg" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-App" role="tabpanel" aria-labelledby="nav-App-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Nurture leads and engage customers through personalized email campaigns and marketing automation.</p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Email campaign strategy development.                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Email list segmentation and targeting.</span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Personalized email content creation.                                                   </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Automated email workflows and sequences.                                                   </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">A/B testing for email performance optimization.                                                  </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Email deliverability monitoring.                                                   </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Email analytics and reporting.                                                 </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Subscriber engagement and retention strategies.                      </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/EmailMarketing.jpeg" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="tab-pane fade" id="nav-Analytics" role="tabpanel" aria-labelledby="nav-Analytics-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Anaaaa cursus a sit amet maur is. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare. Lorem Ipsum aenean commo dolig. Proin qual de suis erestopius. liqueenean sollicituin. lorem quis bibendum auct ornisi elit consequat ipsum, nec sagittis sem nibh id elit. </p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/all.png" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-4 py-90 px-50">
                <div id="myChart">
<Donut/>
                </div>
            </div> */}
                    </div>
                </div>

            </section>


            {/* <!-- tabs section ends --> */}
            <VidBanner />






        </>
    )
}

export default DigitalMain