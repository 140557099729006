import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, EffectCube } from 'swiper';
import Page1 from './Page1';
import Page2 from './Page2';
import 'swiper/swiper-bundle.min.css';
import './Pstyle.css'
import './CrmMega.css'
// import './CrmApp.css'
import { computeHeadingLevel } from '@testing-library/react';
import ExportImg from './CrmImg/ExportImg.png'
import ClientImg from './CrmImg/ClientImg.png'
import ActionPlan from './CrmImg/actionplan.png'
import taskmgmt from './CrmImg/taskmgt.png'
import dailyperformance from './CrmImg/dailyperf.png'
import notifications from './CrmImg/notification.png'
import email from './CrmImg/email.png'
import emailOne from './CrmImg/email1.png'
import Chat from './CrmImg/chat.png'
import propo_dynamic from './CrmImg/proposal-dyn.png'
import departmentMImg from './CrmImg/Department-master.png'
import hierarchyImg from './CrmImg/hierarchy.png'
import proposalMImg from './CrmImg/proposal-master.png'
import proposalOImg from './CrmImg/proposal-others.png'
import estimateImg from './CrmImg/estimate.png'
import userMImg from './CrmImg/user-master.png'
import smtpImg from './CrmImg/smtp.png'
import ticketImg from './CrmImg/ticket-mgt.png'
import internalImg from './CrmImg/internal.png'
import paymentGImg from './CrmImg/pg.png'


const ExpandableList = ({ heading, items, onClick, activeLink, handleLinkClick, isExpanded, handleClick }) => {
    const handleItemClick = (index) => {
        onClick(index);
        handleLinkClick(index);
        // handleClick(index);
    };
    
    return (
        <ul style={{ position: 'relative', alignSelf: 'flex-start' }}>
            <h4 style={{ justifyContent: 'space-between' }} className={`rectangle ${isExpanded ? 'expanded' : ''}`} onClick={handleClick}>
                {heading} <i style={{ color: '', marginRight: '4px' }} className={`fa-solid fa-angle-${isExpanded ? 'down' : 'right'}`}></i>
            </h4>
            {isExpanded && (
                <div style={{ position: 'relative', top: '100%', left: 0, flexDirection: 'column', display: 'flex' }}>
                    {items.map((item, index) => (
                        <li
                            className={`${activeLink === index ? 'underline active' : ''}`}
                            onClick={() => handleItemClick(index)}
                            key={index}
                        >
                            <a href="#">{item}</a>
                        </li>
                    ))}
                </div>
            )}
        </ul>
    );
};


SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectCube]);

const Slider = ({ initialActiveLinkOne, initialActiveLinkTwo, initialActiveLinkThree,initialActiveLinkFour,initialActiveLinkFive,initialActiveLinkSix,initialActiveLinkseven,initialActiveLinkEight, initialActiveLinkNine }) => {

    const swiperRef = React.useRef(null);
    const [activeLinkOne, setActiveLinkOne] = useState(0);
    const [activeLinkTwo, setActiveLinkTwo] = useState(0);
    const [activeLinkThree, setActiveLinkThree] = useState(0);
const [activeLinkFour, setActiveLinkFour] = useState(0);
    const [activeLinkFive, setActiveLinkFive] = useState(0);
    const [activeLinkSix, setActiveLinkSix] = useState(0);
    const [activeLinkseven, setActiveLinkSeven] = useState(0);
    const [activeLinkEight, setActiveLinkEight] = useState(0);
    const [activeLinkNine, setActiveLinkNine] = useState(0);
    const [expandedMenuIndex, setExpandedMenuIndex] = useState(0);
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0); // Define slide index state

    const [pageArrOne] = useState([{ subHeading: "Bulk Data Import-Export", img: ExportImg, pointsArr: ['Streamlined process for importing large volumes of data into the CRM system.', 'Supports various data formats such as CSV, Excel, and XML for easy upload.', `Mapping tools to match fields and attributes between imported data and CRM database.`, `Automated validation checks to ensure data accuracy and completeness during import.`, `Ability to schedule recurring imports for regular updates and data synchronization.`, `Effortless export of CRM data in bulk for analysis, reporting, or backup purposes.`, `Flexible export options to choose specific data fields, filters, and date ranges.`, `Export formats include CSV, Excel, PDF, and custom formats based on your needs.`] }, { subHeading: "Lead Assignment", img: ClientImg, pointsArr: [`User-friendly interface for manual lead assignment by managers or team leads.`, `Visibility into lead ownership and assignment history for accountability and tracking.`, `Collaboration features for team members to communicate and coordinate on assigned leads.`, `Ability to reassign leads as needed to optimize lead distribution and response times.`] }, { subHeading: "Action Plan", img: ActionPlan, pointsArr: [`Create customized action plans for different stages of the customer journey, such as lead nurturing, follow-ups, and customer onboarding.`, `Define specific tasks, deadlines, and responsibilities within each action plan to ensure clarity and accountability.`, `Incorporate automated triggers and notifications to prompt team members to complete action plan tasks on time.`, `Track progress and completion status of action plan tasks in real time.`] },]);

    const [pageArrTwo] = useState([{
        subHeading: "Task Management", img: taskmgmt, pointsArr: [`Detailed descriptions for tasks to provide context and instructions.`, `Ability to create task checklists with sub-tasks and checkboxes for progress tracking.`, `File Attachments: Attach relevant files, documents, or images to tasks for easy reference and collaboration.`, `Set reminders and notifications for upcoming tasks or deadlines to ensure timely completion.`, `Define task dependencies to establish sequential order or prerequisites for task completion.`, `Define task dependencies to establish sequential order or prerequisites for task completion.`, `  Mark important milestones within tasks to track progress and celebrate achievements.`, `  View task activity history, including updates, comments, and changes made by team members.`, `Tag tasks with relevant keywords or labels for easy categorization and retrieval.
    `, `Recurring Tasks: Create recurring tasks for routine activities or ongoing projects with customizable frequency and start/end dates.`]
    }, { subHeading: "Daily Performance", img: dailyperformance, pointsArr: [] }, { subHeading: "Attendence", img: ActionPlan, pointsArr: [] }]);

    const [pageArrThree] = useState([{ subHeading: "Whatsapp Marketing", img: taskmgmt, pointsArr: [] }, { subHeading: "Bulk Email Marketiing", img: email, pointsArr: [] }, { subHeading: "Cold Calling", img: ActionPlan, pointsArr: [] }])

    const [pageArrFour] = useState([{ subHeading: "Notifications", img: notifications, pointsArr: [`Instant notifications for new leads, tasks, appointments, or updates within the CRM system.`] }]);

    const [pageArrFive] = useState([{
        subHeading: "Chat Bot", img: Chat, pointsArr: [`Internal chatbot for providing automated assistance and support to CRM users within the platform.`, `Instant responses to common queries, FAQs, and troubleshooting guidance for quick resolutions.
    Integration with knowledge bases and resources to access relevant information and resources.`]
    }]);

    const [pageArrSix] = useState([{
        subHeading: "Master Setup", img: proposalMImg, pointsArr: [`proposal master: Create professional-looking proposals quickly by filling in details such as client information, project scope, and pricing.`, `Customize fonts, colors, and branding elements to align with your company's style and identity.
    `, `Use dynamic content blocks to insert personalized elements such as client names, company logos, and project specifics into proposals.`, `Ensure consistency and accuracy across proposals while adapting content based on client needs and preferences.`, `Streamline proposal creation by reusing content blocks for commonly used sections such as introduction, services offered, and pricing details.`]
    }, { subHeading: "Dynamic Settings", img: propo_dynamic, pointsArr: [`Automatically populate proposal sections with relevant CRM data, ensuring accuracy and relevance.`] }]);

    const [pageArrSeven] = useState([{
        subHeading: "Invoices", img: taskmgmt, pointsArr: [` Generate invoices automatically based on CRM transactions, orders, or service agreements`, `Customize invoice templates with company branding, payment terms, and itemized details`, `Set up recurring invoicing for subscription-based services or ongoing contracts.
    `]
    }, { subHeading: "Estimates", img: estimateImg, pointsArr: [` Create detailed and professional estimates within the CRM platform for potential projects, services, or products.`, `Capture client feedback, comments, and acceptance of estimates within CRM records.`, `Convert approved estimates into invoices seamlessly within the CRM platform.`] }, { subHeading: "Payments", img: ClientImg, pointsArr: [` Track payment status, transaction history, and payment logs within CRM records.`, `Set up automated payment reminders and notifications for overdue payments.`, `Send payment confirmation emails or SMS alerts upon successful payments.`] }, { subHeading: "Expenses", img: ClientImg, pointsArr: [`Capture and track expenses directly within the CRM platform for accurate financial record-keeping.`] }]);

    const [pageArrEight] = useState([{ subHeading: "User Master", img: userMImg, pointsArr: [`Create and manage user profiles within the CRM system.`, `Customize user profiles with details such as name, contact information, role, and permissions.`, `Assign unique usernames and passwords for secure access to CRM functionalities.`] }, { subHeading: "Department Master", img: departmentMImg, pointsArr: [`Create and manage departments within the CRM system to organize users and resources.`, `Define department names, descriptions, and hierarchical structures for better categorization.`, `Assign department heads or managers to oversee department operations.`] }]);

    const [pageArrNine] = useState([{ subHeading: "Payment Gateway", img: paymentGImg, pointsArr: [`Integrate popular payment gateways directly into the CRM platform for seamless payment processing.`, `Accept payments from customers within the CRM environment, eliminating the need for separate payment portals.`, `Support multiple payment methods such as credit/debit cards, bank transfers, and digital wallets for customer convenience.`] }, { subHeading: "SMTP Integration", img: smtpImg, pointsArr: [` Set up SMTP (Simple Mail Transfer Protocol) settings within the CRM platform for email communication.`, `Automate email campaigns, follow-ups, and notifications using SMTP-enabled automation features.`] }, { subHeading: "SMS Gateway", img: ClientImg, pointsArr: [` Integrate SMS gateway services directly into the CRM platform for seamless SMS communication.`] }, { subHeading: 'Whatsapp Integration', img: ClientImg, pointsArr: [`Integrate WhatsApp Business API with CRM to enable seamless communication via WhatsApp.`, `Obtain API credentials and configure WhatsApp Business settings within the CRM platform.`, `Ensure compliance with WhatsApp Business API guidelines and policies.`] }, { subHeading: 'Calling System', img: ClientImg, pointsArr: [] }]);

    const [pageArrTen] = useState([{
        subHeading: "Ticket Management", img: ticketImg, pointsArr: [`Ensure tickets are assigned to the most appropriate team or agent for prompt resolution.
    Set escalation rules to prioritize critical tickets and ensure timely resolution.
    `]
    }]);

    const handleLinkClickOne = (link) => {
        setActiveLinkOne(link);
    };

    const handleLinkClickTwo = (link) => {
        setActiveLinkTwo(link);
    };

    const handleLinkClickThree = (link) => {
        setActiveLinkThree(link);
    };
    const handleLinkClickFour = (link) => {
        setActiveLinkFour(link);
    };
    const handleLinkClickFive = (link) => {
        setActiveLinkFive(link);
    };
    const handleLinkClickSix = (link) => {
        setActiveLinkSix(link);
    };
    const handleLinkClickSeven = (link) => {
        setActiveLinkSeven(link);
    };
    const handleLinkClickEight = (link) => {
        setActiveLinkEight(link);
    };
    const handleLinkClickNine = (link) => {
        setActiveLinkNine(link);
    };

    const handleMenuClick = (index) => {
        setExpandedMenuIndex(index === expandedMenuIndex ? null : index);
    };

    const goToSlide = (index) => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideTo(index);
        }

        // const handleSlideChange = (swiper) => {
        //     const newSlideIndex = swiper.activeIndex;
        //     // Update active links based on the current expanded menu
        //     if (expandedMenuIndex === 0) {
        //         setActiveLinkOne(newSlideIndex);
        //     } else if (expandedMenuIndex === 1) {
        //         setActiveLinkTwo(newSlideIndex);
        //     } else if (expandedMenuIndex === 3) {
        //         setActiveLinkThree(newSlideIndex);
        //     }else if (expandedMenuIndex === 4) {
        //         setActiveLinkFour(newSlideIndex);
        //     }else if (expandedMenuIndex === 5) {
        //         setActiveLinkFive(newSlideIndex);
        //     }else if (expandedMenuIndex === 6) {
        //         setActiveLinkSix(newSlideIndex);
        //     }else if (expandedMenuIndex === 7) {
        //         setActiveLinkSeven(newSlideIndex);
        //     }else if (expandedMenuIndex === 8) {
        //         setActiveLinkEight(newSlideIndex);
        //     }else if (expandedMenuIndex === 9) {
        //         setActiveLinkNine(newSlideIndex);
        //     } 
        // };
    };


    return (
        <>
            <div className="p-body mainCrmSlider" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div className='intro' >
                    <h4 className="rectangle intro-menu" style={{ display: 'flex', justifyContent: 'right', width: '105%' }}>
                        <div className="intro-btn wobble-hor-bottom"><i className=""><svg xmlns="http://www.w3.org/2000/svg" width="20" height="30" fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg></i>
                        </div>
                    </h4>
                    <div>

                        <ExpandableList
                            heading="Lead Management"
                            items={['Bulk Data Import-Export',
                                'Lead Assignment',
                                'Action Plan']}
                            onClick={goToSlide}
                            activeLink={activeLinkOne}
                            handleLinkClick={handleLinkClickOne}
                            isExpanded={expandedMenuIndex === 0}
                            handleClick={() => handleMenuClick(0)}
                        />

                    </div>
                    <div>
                        <ExpandableList
                            heading="Team Management"
                            // items={['Task Management', 'Daily Performance', 'Attendance', 'Salary Management']}
                            items={['Task Management', 'Daily Performance', 'Attendance']}
                            onClick={goToSlide}
                            activeLink={activeLinkTwo}
                            handleLinkClick={handleLinkClickTwo}
                            isExpanded={expandedMenuIndex === 1}
                            handleClick={() => handleMenuClick(1)}
                        />
                    </div>

                    {/* <div>
                        <ExpandableList
                            heading="Campaign Management"
                            items={['Whatsapp Marketing', 'Bulk Email Marketing', 'Cold Calling']}
                            activeLink={activeLinkThree}
                            handleLinkClick={handleLinkClickThree}
                            isExpanded={expandedMenuIndex === 2}
                            handleClick={() => handleMenuClick(2)}
                            onClick={goToSlide}
                        />
                    </div> */}
                    <div>
                        <ExpandableList
                            heading="Follow up & Reminders"
                            // items={['Notifications', 'Whatsapp Broadcast', 'Emails']}
                            items={['Notifications']}
                            onClick={goToSlide}
                            activeLink={activeLinkThree}
                            handleLinkClick={handleLinkClickThree}
                            isExpanded={expandedMenuIndex === 3}
                            handleClick={() => handleMenuClick(3)}
                        />
                    </div>
                    <div>
                        <ExpandableList
                            heading="Chat Bot"
                            items={['Chat Bot']}
                            onClick={goToSlide}
                            activeLink={activeLinkFour}
                            handleLinkClick={handleLinkClickFour}
                            isExpanded={expandedMenuIndex === 4}
                            handleClick={() => handleMenuClick(4)}
                        />
                    </div>
                    <div>
                        <ExpandableList
                            heading="Proposals"
                            // items={['Master Setup', 'Dynamic Settings', 'Other Settings']}
                            items={['Master Setup', 'Dynamic Settings']}
                            onClick={goToSlide}
                            activeLink={activeLinkFive}
                            handleLinkClick={handleLinkClickFive}
                            isExpanded={expandedMenuIndex === 5}
                            handleClick={() => handleMenuClick(5)}
                        />
                    </div>
                    <div>
                        <ExpandableList
                            heading="Finance Management"
                            items={['Invoices', 'Estimates', 'Payments', 'Expenses']}
                            onClick={goToSlide}
                            activeLink={activeLinkSix}
                            handleLinkClick={handleLinkClickSix}
                            isExpanded={expandedMenuIndex === 6}
                            handleClick={() => handleMenuClick(6)}
                        />
                    </div>
                    <div>
                        <ExpandableList
                            heading="Users & Deparment"
                            items={['User Master', 'Department Master']}
                            onClick={goToSlide}
                            activeLink={activeLinkseven}
                            handleLinkClick={handleLinkClickSeven}
                            isExpanded={expandedMenuIndex === 7}
                            handleClick={() => handleMenuClick(7)}
                        />
                    </div>
                    <div>
                        <ExpandableList
                            heading="Third Party Integration"
                            items={['Payment Gateway', 'SMTP Integration', 'SMS Gateway', 'Whatsapp Integration', 'Calling System']}
                            onClick={goToSlide}
                            activeLink={activeLinkEight}
                            handleLinkClick={handleLinkClickEight}
                            isExpanded={expandedMenuIndex === 8}
                            handleClick={() => handleMenuClick(8)}
                        />
                    </div>
                    <div>
                        <ExpandableList
                            heading="Support"
                            items={['Ticket Management']}
                            onClick={goToSlide}
                            activeLink={activeLinkNine}
                            handleLinkClick={handleLinkClickNine}
                            isExpanded={expandedMenuIndex === 9}
                            handleClick={() => handleMenuClick(9)}
                        />
                    </div>
                </div>
                <Swiper
                    ref={swiperRef}
                    spaceBetween={50}
                    slidesPerView={6}
                    navigation={{ clickable: true }}
                    pagination={true}
                    scrollbar={{ draggable: true }}
                    effect="cube"
                    cubeEffect={{
                        shadow: false,
                        slideShadows: true,
                        shadowOffset: 20,
                        shadowScale: 0.94,
                    }}
                    onSlideChange={(swiper) => {
                        setCurrentSlideIndex(swiper.activeIndex);
                        // Update active links based on the current expanded menu
                        if (expandedMenuIndex === 0) {
                            setActiveLinkOne(swiper.activeIndex);
                        } else if (expandedMenuIndex === 1) {
                            setActiveLinkTwo(swiper.activeIndex);
                        }  else if (expandedMenuIndex === 3) {
                            setActiveLinkThree(swiper.activeIndex);
                        }else if (expandedMenuIndex === 4) {
                            setActiveLinkFour(swiper.activeIndex);
                        }else if (expandedMenuIndex === 5) {
                            setActiveLinkFive(swiper.activeIndex);
                        }else if (expandedMenuIndex === 6) {
                            setActiveLinkSix(swiper.activeIndex);
                        }else if (expandedMenuIndex === 7) {
                            setActiveLinkSeven(swiper.activeIndex);
                        }else if (expandedMenuIndex === 8) {
                            setActiveLinkEight(swiper.activeIndex);
                        }else if (expandedMenuIndex === 9) {
                            setActiveLinkNine(swiper.activeIndex);
                        }
                    }}
                    id='p-swipper'
                >
                    {expandedMenuIndex === 0 ? pageArrOne.map((item, index) => <SwiperSlide key={index}>
                        <Page1 index={index + 1} num={1} mainHead={"Lead Management"} items={item} />
                    </SwiperSlide>)
                        : expandedMenuIndex === 1 ? pageArrTwo.map((item, index) => <SwiperSlide key={index}>
                            <Page1 index={index + 1} num={2} mainHead={"Team Management"} items={item} />
                        </SwiperSlide>)
                            // : expandedMenuIndex === 2 ? pageArrThree.map((item, index) => <SwiperSlide key={index}>
                            //     <Page1 index={index + 1} num={3} mainHead={"Campaign Management"} items={item} />
                            // </SwiperSlide>)
                            : expandedMenuIndex === 3 ? pageArrFour.map((item, index) => <SwiperSlide key={index}>
                                <Page1 index={index + 1} num={3} mainHead={"Follow up & Reminders"} items={item} />
                            </SwiperSlide>)
                                : expandedMenuIndex === 4 ? pageArrFive.map((item, index) => <SwiperSlide key={index}>
                                    <Page1 index={index + 1} num={4} mainHead={"Chat Bot"} items={item} />
                                </SwiperSlide>)
                                    : expandedMenuIndex === 5 ? pageArrSix.map((item, index) => <SwiperSlide key={index}>
                                        <Page1 index={index + 1} num={5} mainHead={"Propsals"} items={item} />
                                    </SwiperSlide>)
                                        : expandedMenuIndex === 6 ? pageArrSeven.map((item, index) => <SwiperSlide key={index}>
                                            <Page1 index={index + 1} num={6} mainHead={"Finance Management"} items={item} />
                                        </SwiperSlide>)
                                            : expandedMenuIndex === 7 ? pageArrEight.map((item, index) => <SwiperSlide key={index}>
                                                <Page1 index={index + 1} num={7} mainHead={"Users  & Department"} items={item} />
                                            </SwiperSlide>)
                                                : expandedMenuIndex === 8 ? pageArrNine.map((item, index) => <SwiperSlide key={index}>
                                                    <Page1 index={index + 1} num={8} mainHead={"Third Party Integration"} items={item} />
                                                </SwiperSlide>)
                                                    : expandedMenuIndex === 9 ? pageArrTen.map((item, index) => <SwiperSlide key={index}>
                                                        <Page1 index={index + 1} num={9} mainHead={"Support"} items={item} />
                                                    </SwiperSlide>)
                                                        : pageArrOne.map((item, index) => <SwiperSlide key={index}>
                                                            <Page1 index={index + 1} mainHead={"Lead Management"} items={item} />
                                                        </SwiperSlide>)}
                </Swiper>
            </div>
        </>
    );
};

export default Slider

