import React from 'react';
import { Link } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import crmOne from './CrmImg/crmOne.png'
import crmTwo from './CrmImg/crmTwo.png'
import crmThree from './CrmImg/crmThree.png'
import crmFour from './CrmImg/crmFour.png'
import { useSelector } from 'react-redux';
const DummySaasMain = () => {

    const saascategories = useSelector(state => state.SliceData.saascategories);
    // const saasclients = useSelector(state => state.SliceData.saasclients);
    const saasprocess = useSelector(state => state.SliceData.saasprocess);

    return (
        <>
            {/* <!-- boxed container starts --> */}
            {/* <section className="boxed-contt">

                <div className="large-too">
                    <span id='dummySaaService'>Modules</span>
                </div>
            </section> */}
            {/* <div className="row saas-service-icon">
                {saascategories?.map((item, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                        <div className="boxed d-flex">
                            <div className="icon-box "> */}
                                {/* <i className="fa-solid fa-pen-ruler"></i> */}
                            {/* </div>
                            <div className="text-box">
                                <h5><Link to="">{item?.title}</Link></h5>
                                <p>{item?.content}</p>
                                <Link to="" className="hover-link"><span className="icon-btn"><i className="fa-solid fa-plus"></i></span>
                                    <span className="btn-infor">Read more</span></Link>
                            </div>
                        </div>
                    </div>
                ))}
            </div> */}

            {/* <!-- boxedd container ends --> */}
            {/* <!-- testimonial starts --> */}

            {/* <!-- tabs section starts --> */}

            <section className="tab-sec ">
                <div className="container">
                    {/* <div className="main-title text-center">
                        <h2>Website Support We Offer</h2>

                    </div> */}
                    <div className="contact-text web-text my-auto text-center main-title py-80">
                        <div className="head text-center ">
                            <p className="first mx-auto">
                                Achieve CRM full potential
                            </p>
                        </div>
                        <h2>CRM Support We Offer</h2>
                    </div>
                    <div className="w-100">
                        <div className="row">
                            <div className="col-lg-12 px-0">
                                <div className="bg-gr web-men">
                                    <nav>
                                        <div className="nav justify-content-center nav-tabs" id="nav-tab" role="tablist">
                                            <button className="nav-lin active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Tailored Solutions</button>
                                            <button className="nav-lin" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Data Migration</button>
                                            <button className="nav-lin" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Training and Onboarding</button>
                                            {/* <button className="nav-lin" id="nav-speed-tab" data-bs-toggle="tab" data-bs-target="#nav-speed" type="button" role="tab" aria-controls="nav-speed" aria-selected="false">Data Service</button> */}
                                            <button className="nav-lin" id="nav-ams-tab" data-bs-toggle="tab" data-bs-target="#nav-ams" type="button" role="tab" aria-controls="nav-ams" aria-selected="false">Dedicated Support</button>
                                            <div className="animation start-home" id="marker"></div>
                                        </div>
                                    </nav>
                                </div>
                                <div className="tab-content px-3" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className="row">
                                            <div className="col-lg-8 order-2 dummySaasText order-lg-1">
                                                <div className="contact-text my-auto py-80">
                                                    <p> We understand that every business is unique. Our team customizes your CRM system to align with your specific needs, ensuring maximum efficiency and effectiveness.</p>
                                                    <div className="consult-address">

                                                        <div className="row">
                                                            <div className="col-lg-6 mb-4">
                                                                <ul>
                                                                    <li className="d-flex  quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">In-depth consultation to understand your business processes and requirements.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Customization of CRM modules, fields, and workflows to match your unique workflows.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Integration of industry-specific features and functionalities for targeted solutions.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Personalized user interfaces tailored to different roles within your organization.</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Implementation of automation rules and triggers based on your specific business rules.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Configuration of advanced reporting dashboards to track KPIs relevant to your business.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Scalability options to accommodate future growth and evolving business needs.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Seamless integration with existing tools and software used by your team.</span>
                                                                    </li>


                                                                </ul>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/* <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 my-auto order-1 order-lg-2">
                                                <div className='w-100'>

                                                    <div className='saas-img py-3'>

                                                        <img src={crmOne} className="img-responsive frame-img" alt="" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <div className="row">
                                            <div className="col-lg-8 order-2 dummySaasText order-lg-1">
                                                <div className="contact-text my-auto py-80 ">

                                                    <p>Transitioning from legacy systems or importing data from spreadsheets? Our experts handle data migration smoothly, ensuring data accuracy and completeness.</p>
                                                    <div className="consult-address">

                                                        <div className="row">
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Assessment of existing data sources and formats to create a migration strategy.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Data cleansing and de-duplication to ensure the accuracy and integrity of migrated data.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Mapping of fields and attributes between old and new systems for seamless integration.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Conversion of data formats, if necessary, to match the requirements of the new CRM system.</span>
                                                                    </li>

                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Testing and validation of migrated data to identify and rectify any discrepancies.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Post-migration support to address any issues and ensure smooth transition and data continuity.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Training for your team on how to navigate and use the migrated data effectively within the new CRM system.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Regular audits and checks to maintain data quality and consistency after migration.</span>
                                                                    </li>


                                                                </ul>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/* <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 my-auto order-1 order-lg-2">
                                                <div className='w-100'>

                                                    <div className='saas-img py-3'>

                                                        <img src={crmTwo} className="img-responsive frame-img" alt="" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div className="row">
                                            <div className="col-lg-8 order-2 dummySaasText order-lg-1">
                                                <div className="contact-text my-auto py-80">

                                                    <p>Empower your team with comprehensive training sessions and onboarding support. From basic CRM usage to advanced features, we've got you covered.</p>
                                                    <div className="consult-address">

                                                        <div className="row">
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Personalized onboarding sessions tailored to different user roles within your organization.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Hands-on training covering basic CRM navigation, data entry, and contact management.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Advanced training modules focused on utilizing CRM features such as automation, reporting, and integrations.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Role-based training to ensure each team member understands their specific responsibilities within the CRM system.</span>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Training materials and resources, including user guides, video tutorials, and FAQs, for ongoing reference and learning.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Customized training schedules to accommodate different learning preferences and availability of team members.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Dedicated support during the onboarding process to address any questions or challenges that arise.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Regular follow-ups and check-ins to assess progress, provide additional training as needed, and ensure optimal usage of the CRM system.</span>
                                                                    </li>


                                                                </ul>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/* <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link> */}
                                                </div>
                                            </div>
                                            <div className="col-lg-4 my-auto order-1 order-lg-2">
                                                <div className='w-100'>

                                                    <div className='saas-img py-3'>

                                                        <img src={crmThree} className="img-responsive frame-img" alt="" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="tab-pane fade" id="nav-speed" role="tabpanel" aria-labelledby="nav-speed-tab">
                                        <div className="row">
                                            <div className="col-lg-8 order-2 order-lg-1">
                                                <div className="contact-text my-auto py-80">

                                                    <p>Vulputate cursus a sit amet maur is. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare. Lorem Ipsum aenean commo dolig. Proin qual de suis erestopius. liqueenean sollicituin. lorem quis bibendum auct ornisi elit consequat ipsum, nec sagittis sem nibh id elit. </p>
                                                    <div className="consult-address">

                                                        <div className="row">
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Access to a dedicated team of CRM experts who are available to assist you with any questions or issues.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Prompt response times to ensure timely resolution of technical issues and inquiries.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Proactive monitoring of your CRM system to identify potential issues before they impact your operations.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Regular check-ins and follow-ups to ensure ongoing satisfaction and address any evolving needs.</span>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Assistance with customization and optimization of your CRM system to align with your business goals.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Training and guidance on advanced features and best practices to maximize the value of your CRM investment.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Escalation procedures for urgent matters, ensuring quick resolution and minimal downtime.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Continuous improvement initiatives to enhance the performance and functionality of your CRM system over time.</span>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 my-auto order-1 order-lg-2">
                                                <div className='w-100'>

                                                    <div className='saas-img py-3'>

                                                        <img src="assets/Images/all.png" className="img-responsive frame-img" alt="" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="tab-pane fade" id="nav-ams" role="tabpanel" aria-labelledby="nav-ams-tab">
                                        <div className="row">
                                            <div className="col-lg-8 order-2 dummySaasText order-lg-1">
                                                <div className="contact-text  my-auto py-80">

                                                    <p>Receive ongoing support and assistance from our dedicated team of CRM experts. From troubleshooting to optimization, we're here to help you every step of the way.</p>
                                                    <div className="consult-address">

                                                        <div className="row">
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Access to a dedicated team of CRM experts who are available to assist you with any questions or issues.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Prompt response times to ensure timely resolution of technical issues and inquiries.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Proactive monitoring of your CRM system to identify potential issues before they impact your operations.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Regular check-ins and follow-ups to ensure ongoing satisfaction and address any evolving needs.</span>
                                                                    </li>


                                                                </ul>
                                                            </div>
                                                            <div className="col-lg-6 mb-4">

                                                                <ul>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Assistance with customization and optimization of your CRM system to align with your business goals.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Training and guidance on advanced features and best practices to maximize the value of your CRM investment.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Escalation procedures for urgent matters, ensuring quick resolution and minimal downtime.</span>
                                                                    </li>
                                                                    <li className="d-flex quick mb-1">
                                                                        <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                        <span className="check-text">Continuous improvement initiatives to enhance the performance and functionality of your CRM system over time.</span>
                                                                    </li>


                                                                </ul>
                                                            </div>


                                                        </div>
                                                    </div>
                                                    {/* <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link> */}
                                                </div>
                                            </div>

                                            <div className="col-lg-4 my-auto order-1 order-lg-2">
                                                <div className='w-100'>

                                                    <div className='saas-img py-3'>
                                                        <img src={crmFour} className="img-responsive frame-img" alt="" />

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 py-90 px-50">
                <div id="myChart">
<Donut/>
                </div>
            </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            {/* <!-- tabs section ends --> */}

            {/* <div className='top-slide-hid'>
                <div className="top-swiper">
                    <div className="data order-2 order-md-2">


                    </div>
                    <div className="heading-2 order-1 order-md-1">
                        <h3>What our client says</h3>
                    </div>
                </div>
                <Swiper
                    
                    modules={[Navigation, Pagination, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    direction="vertical"
                    loop={true}
                    speed={800}
                    pagination={{ clickable: true }}
                   
                    className='swiper'
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >
                   

                  
                    {saasclients?.map((item, index) => (
                    <SwiperSlide className='swiper-slide d-flex flex-md-row flex-column' key={index}>
                        
                        <div className="image-slider ">
                            <img src={`${WebUrl}/${item?.image}`} className="mx-auto" width="146px" alt="" />
                        </div>
                        <div className="slide-text">
                            <p>{item?.review}</p>

                            <h2>{item?.name}</h2>
                        </div>
                        
                    </SwiperSlide>
                    ))}
                    
                </Swiper>
            </div> */}

            {/* <!-- process starts --> */}

            {/* <section className="process py-80">
                <div className="container">
                    <div className="main-title text-center">
                        <h2>Our Process</h2>

                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="process-img">
                                <img width="100%" src="assets/Images/Home-4-phone-img.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 pt-5">
                            {saasprocess?.map((item, index) => (
                                <div className="row" key={index}>
                                    <div className="col-3">
                                        <div className="process-left">
                                            <div className="process-number">
                                                0{index + 1}
                                            </div>
                                            <div className="process-line"></div>
                                        </div>
                                    </div>

                                    <div className="col-9 p-3">
                                        <div className="process-txt">
                                            <h5>{item?.title}</h5>
                                            <p>{item?.content}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </section> */}

            {/* <!-- process ends --> */}
        </>
    )
}

export default DummySaasMain