import React from 'react';
import { Link } from 'react-router-dom';
// import Swiper core and required modules
// import { Navigation, Pagination, A11y } from 'swiper';

// import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
// import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { useSelector } from 'react-redux';
// import Donut from './Donut';
import LogoSlider from './LogoSlider';
// import { WebUrl } from '../../../BaseUrl';



const WebFrameMain = () => {

    const websitecategories = useSelector(state => state.SliceData.websitecategories);
    // const saasclients = useSelector(state => state.SliceData.saasclients);
    const websiteprocess = useSelector(state => state.SliceData.websiteprocess);

    return (
        <>
            {/* <!-- boxed container starts --> */}
            <section className="boxed-contt">

                <div className="large-too">
                    <span>SERVICES</span>
                </div>
            </section>
            <div className="row web-service-icon">
                {websitecategories?.map((item, index) => (
                    <div className="col-lg-4 col-md-6" key={index}>
                        <div className="boxed d-flex">
                            <div className="icon-box">
                                {/* <i className="fa-solid fa-pen-ruler"></i> */}
                            </div>
                            <div className="text-box">
                                <h5><Link to="">{item?.title}</Link></h5>
                                <p>{item?.content}</p>
                                <Link to="" className="hover-link"><span className="icon-btn"><i className="fa-solid fa-plus"></i></span>
                                    <span className="btn-infor">Read more</span></Link>
                            </div>
                        </div>
                    </div>
                ))}

            </div>

            {/* <!-- boxedd container ends --> */}
            {/* <!-- testimonial starts --> */}


            {/* <div className='top-slide-hid'>
                <div className="top-swiper">
                    <div className="data order-2 order-md-2">


                    </div>
                    <div className="heading-2 order-1 order-md-1">
                        <h3>What our client says</h3>
                    </div>
                </div>
                <Swiper
                    
                    modules={[Navigation, Pagination, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    direction="vertical"
                    loop={true}
                    speed={800}
                    pagination={{ clickable: true }}
                    
                    className='swiper'
                    onSwiper={(swiper) => console.log(swiper)}
                    onSlideChange={() => console.log('slide change')}
                >
                   

                  
                    {saasclients?.map((item, index) => (
                    <SwiperSlide className='swiper-slide d-flex flex-md-row flex-column' key={index}>
                       
                        <div className="image-slider ">
                            <img src={`${WebUrl}/${item?.image}`} className="mx-auto" width="146px" alt="" />
                        </div>
                        <div className="slide-text">
                            <p>{item?.review}</p>

                            <h2>{item?.name}</h2>
                        </div>
                        
                    </SwiperSlide>
                    ))}
               
                </Swiper>
            </div> */}


            {/* <!-- tabs section starts --> */}

            <section className="tab-sec ">
                <div className="container">
                    {/* <div className="main-title text-center">
                        <h2>Website Support We Offer</h2>

                    </div> */}
                    <div className="contact-text web-text my-auto text-center main-title py-80">
                        <div className="head text-center ">
                            <p className="first mx-auto">
                                Achieve your web's full potential
                            </p>
                        </div>
                        <h2>Website Support We Offer</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-12 new-quart px-0">
                            <div className="bg-gr web-men">
                                <nav>
                                    <div className="nav justify-content-start nav-tabs perNav" id="nav-tab" role="tablist">
                                        <button className="nav-lin active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Landing Page</button>
                                        <button className="nav-lin" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Speed Optimization</button>
                                        <button className="nav-lin" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Conversion Rate Optimization</button>
                                        <button className="nav-lin" id="nav-speed-tab" data-bs-toggle="tab" data-bs-target="#nav-speed" type="button" role="tab" aria-controls="nav-speed" aria-selected="false">Annual Maintainance Support</button>
                                        {/* <button className="nav-lin" id="nav-ams-tab" data-bs-toggle="tab" data-bs-target="#nav-ams" type="button" role="tab" aria-controls="nav-ams" aria-selected="false">AMS</button> */}
                                        <div className="animation start-home" id="marker"></div>
                                    </div>
                                </nav>
                            </div>
                            <div className="tab-content px-3" id="nav-tabContent">
                                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Create impactful landing pages to drive conversions and engagement, tailored to your brand's goals and target audience.</p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Customized landing page design.                                               </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Engaging content and visuals.                                            </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Clear call-to-action elements.                                                 </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">A/B testing for optimization.                                                    </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Responsive and user-friendly layout.                                                   </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Integration with analytics tools.                                                   </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Continuous performance monitoring.                                               </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Conversion-focused strategies.                                                 </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/all.png" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80 ">

                                                <p>Optimize website speed for improved user experience, reduced bounce rates, and better search engine rankings. </p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Website performance analysis.                                         </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Minimize page loading times.                                                </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Optimize images and scripts.                                     </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Implement caching techniques.                                                 </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Mobile optimization for fast loading.                                            </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Fix broken links and redirects.                                                </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Enhance server response time.</span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Regular performance audits.</span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/websiteSpeedOptimization.jpg" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Increase website conversion rates through data-driven strategies, user experience improvements, and targeted optimizations. </p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Conversion funnel analysis.                                          </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">User behavior tracking.                                   </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">A/B and multivariate testing.                                              </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Personalized content recommendations.                                                    </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Optimize forms and checkout processes.</span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Improve site navigation and usability.                                               </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Implement exit-intent strategies.                                               </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Continuous optimization based on analytics.                                                  </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/conversionOptimization.jpg" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="nav-speed" role="tabpanel" aria-labelledby="nav-speed-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Ensure the ongoing functionality, security, and performance of your website with comprehensive annual maintenance support. </p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Regular software updates and patches.                                            </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Security audits and vulnerability assessments.                                               </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Backup and recovery solutions.                                                </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Performance monitoring and optimization.                                       </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Technical support and troubleshooting.                                           </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Content updates and revisions.                                          </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Compliance with industry standards.                                           </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proactive maintenance for uninterrupted operation.                                          </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3 d-flex justify-content-center'>
                                                    <img style={{ maxWidth: '70%',  }} src="assets/Images/annualSupport.png" className="img-responsive frame-img" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="tab-pane fade" id="nav-ams" role="tabpanel" aria-labelledby="nav-ams-tab">
                                    <div className="row">
                                        <div className="col-lg-8 order-2 order-lg-1">
                                            <div className="contact-text my-auto py-80">

                                                <p>Vulputate cursus a sit amet maur is. Morbi accumsan ipsum velit. Nam nec tellus a odio tincidunt auctor a ornare. Lorem Ipsum aenean commo dolig. Proin qual de suis erestopius. liqueenean sollicituin. lorem quis bibendum auct ornisi elit consequat ipsum, nec sagittis sem nibh id elit. </p>
                                                <div className="consult-address">

                                                    <div className="row">
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>


                                                            </ul>
                                                        </div>
                                                        <div className="col-lg-6 mb-4">

                                                            <ul>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>
                                                                <li className="d-flex quick mb-1">
                                                                    <span className="check-icon"><i className="fa-regular me-2 fa-square-check"></i></span>
                                                                    <span className="check-text">Proin qual de suis erestopius                                                    </span>
                                                                </li>


                                                            </ul>
                                                        </div>


                                                    </div>
                                                </div>
                                                <Link to="" className="btn btn-new">Read More <i className="fa-solid fa-angle-right ms-1"></i></Link>
                                            </div>
                                        </div>

                                        <div className="col-lg-4 my-auto order-1 order-lg-2">
                                            <div className='w-100'>

                                                <div className='saas-img py-3'>

                                                    <img src="assets/Images/all.png" className="img-responsive frame-img" alt="" />

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="col-lg-4 py-90 px-50">
                <div id="myChart">
<Donut/>
                </div>
            </div> */}
                    </div>
                </div>

            </section>


            {/* <!-- tabs section ends --> */}


            {/* logo slider starts */}

            <LogoSlider />

            {/* logo slider ends */}

            {/* <!-- process starts --> */}

            <section className="process py-80">
                <div className="container">
                    <div className="main-title text-center">
                        <h2>Our Process</h2>

                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="process-img ">
                                <img width="100%" src="assets/Images/Home-4-phone-img.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 pt-5">
                            {websiteprocess?.map((item, index) => (
                                <div className="row" key={index}>
                                    <div className="col-3">
                                        <div className="process-left">
                                            <div className="process-number">
                                                0{index + 1}
                                            </div>
                                            <div className="process-line"></div>
                                        </div>
                                    </div>

                                    <div className="col-9 p-3">
                                        <div className="process-txt">
                                            <h5>{item?.title}</h5>
                                            <p>{item?.content}</p>
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>

            </section>

            {/* <!-- process ends --> */}

        </>
    )
}

export default WebFrameMain