import { React, useEffect } from 'react';
import Header from '../main-components/Header';
import Footer from '../main-components/Footer';
import Slider from './sub-components/Crm/CrmSlider';
import DummySaasMain from './sub-components/Crm/DummySaas';
import { useDispatch } from 'react-redux'
import { BaseUrl } from '../BaseUrl'
import { addSaascategoriesData, addSaasclientsData, addSaasprocessData, addSaasservicesData, addSaasslidersData, addSettingsData } from '../Redux/SliceData/SliceData'
import SliderTest from './sub-components/Crm/CrmSliderTestDev';
const Crm = () => {
    const dispatch = useDispatch();
    useEffect(() => {

        ; (async () => {
            // settings api
            const settings = await fetch(`${BaseUrl}/settings`);
            const settingsJson = await settings.json();
            dispatch(addSettingsData(settingsJson.settings));

            // saasservices api
            const saasservices = await fetch(`${BaseUrl}/saasservices`);
            const saasservicesJson = await saasservices.json();
            dispatch(addSaasservicesData(saasservicesJson.saasservices));

            // saassliders api
            const saassliders = await fetch(`${BaseUrl}/saassliders`);
            const saasslidersJson = await saassliders.json();
            dispatch(addSaasslidersData(saasslidersJson.saassliders));

            // saascategories api
            const saascategories = await fetch(`${BaseUrl}/saascategories`);
            const saascategoriesJson = await saascategories.json();
            dispatch(addSaascategoriesData(saascategoriesJson.saascategories));

            // saasclients api
            const saasclients = await fetch(`${BaseUrl}/saasclients`);
            const saasclientsJson = await saasclients.json();
            dispatch(addSaasclientsData(saasclientsJson.saasclients));

            // saasprocess api
            const saasprocess = await fetch(`${BaseUrl}/saasprocess`);
            const saasprocessJson = await saasprocess.json();
            dispatch(addSaasprocessData(saasprocessJson.saasprocess));

        })();
    }, []);
    return (
        <>
            <section className='web-hero-area'>
                <Header />
            </section>
            <section className="showcase" id='padingTop'>
                {/* <section className='saas-hero'> */}
                    {/* <div className="container-fluid">
                        <div className="text-center main-title-1">
                            <h2>Visom6 CRM</h2>
                            <p>Elevate Your Business, streamlined lead management & seamless team collaboration </p>
                        </div>
                    </div> */}
                    {/* <div className='container pb-40'>
                        <div className='w-100'>

                            <div className='saas-img'>
                                <img src="assets/Images/all.png" className="img-responsive frame-img" alt="" />

                            </div>
                        </div>

                    </div> */}
                {/* </section> */}
                <Slider />
                {/* <SliderTest/> */}
                <DummySaasMain />
            </section>
            {/* <Footer /> */}
        </>
    )
}

export default Crm;