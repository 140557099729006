import React from 'react';
import { useSelector } from 'react-redux';
// import { BaseUrl, WebUrl } from '../../../BaseUrl';

export default function SaasHero() {

    const saasservices = useSelector(state => state.SliceData.saasservices);
    return (
        <>
            <section className="showcase">
                <section className='saas-hero'>
                    <div className="container-fluid">
                        <div className="text-center main-title-1">
                            <h2>{saasservices?.title}</h2>
                            <p>{saasservices?.content}</p>
                        </div>
                    </div>
                    <div className='container pb-40'>
                        <div className='w-100'>

                            <div className='saas-img'>
                                <img src="assets/Images/all.png" className="img-responsive frame-img" alt="" />

                            </div>
                        </div>

                    </div>
                </section>
            </section>

        </>
    )
}

