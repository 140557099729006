

const data = {
    cardData:[
        {
            id:1,
            img:'assets/Images/government.jpg',
            title:'Government',
        },
        {
            id:2,
            img:'assets/Images/automobile-manufacturing.jpg',
            title:'Automobile Manufacturing',
        },
        {
            id:3,
            img:'assets/Images/incubation-centre.jpg',
            title:'Incubation Centres',
        },
        {
            id:4,
            img:'assets/Images/banks.jpg',
            title:'Banks',
        },
        {
            id:5,
            img:'assets/Images/jewellery.jpg',
            title:'Gem & Jewellery',
        },
        {
            id:6,
            img:'assets/Images/university.jpg',
            title:'Universities',
        },
        {
            id:7,
            img:'assets/Images/healthcare.jpg',
            title:'HealthCare Software',
        },
        {
            id:8,
            img:'assets/Images/it.jpg',
            title:'IT',
        },
        // {
        //     id:9,
        //     img:'assets/Images/tierra-mallorca-rgJ1J8SDEAY-unsplash.jpg',
        //     title:'Real Estate',
        // },
        
        
    ]
  
}

export default data;