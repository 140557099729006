// import React, { useState } from 'react';
import React from 'react';
import { Link } from 'react-router-dom';
// import Select from 'react-select';
// import CircleCarousel from './CircleCarousel';
import "./dharma-erp.css"

const HomeMain1 = () => {

    return (
        <>
            <div className="py-90 position-relative displayNone">
                <div className="container home-large-txt text-center">
                    <h1># We are Mumbai's <br /> One of the best <br />  Tech Development &amp; <br />  Digital Marketing Companies</h1>
                </div>
                {/* <div className="large-heading-img">
                <img src="assets/Images/gif/large-heading.png" alt="" />
                </div> */}
            </div>


            <div className="dharma-bg displayNone">
                <div className="main-title text-center">
                    <h2>Our Products</h2>

                </div>
                <div className="container h-101">

                    <div className="erp h-100" >
                        <div className="center-erp d-flex justify-content-center align-items-center h-100 mx-auto">
                            <div className="center-erp-circle ">
                                <Link className='text-d-none' to="/saas-services">
                                    <div className="erp-cpu my-auto">
                                        <h6>Dharma <br /> ERP</h6>
                                    </div>
                                </Link>
                                <span className="cpu-line-top-1"></span>
                                <span className="cpu-line-top-2"></span>
                                <span className="cpu-line-top-3"></span>
                                <span className="cpu-line-top-4"></span>

                                <span className="cpu-line-bottom-1"></span>
                                <span className="cpu-line-bottom-2"></span>
                                <span className="cpu-line-bottom-3"></span>
                                <span className="cpu-line-bottom-4"></span>

                                <span className="cpu-line-left-1"></span>
                                <span className="cpu-line-left-2"></span>
                                <span className="cpu-line-left-3"></span>
                                <span className="cpu-line-left-4"></span>

                                <span className="cpu-line-right-1"></span>
                                <span className="cpu-line-right-2"></span>
                                <span className="cpu-line-right-3"></span>
                                <span className="cpu-line-right-4"></span>


                            </div>

                            <div className="left-erp-circle ">

                                <div className="left-erp-1">
                                    <div className="left-erp-bx">
                                        <img src="assets/Images/gif/admin.png" width="100px" alt="" />


                                    </div>
                                    <span className="left-line-1"></span>
                                    <h6>User Admin</h6>
                                </div>
                                <div className="left-erp-2">
                                    <div className="left-erp-bx">
                                        <img src="assets/Images/gif/finance.png" width="100px" alt="" />
                                    </div>
                                    <span className="left-line-2"></span>
                                    <h6>Finance</h6>
                                </div>
                                <div className="left-erp-3">
                                    <div className="left-erp-bx">
                                        <img src="assets/Images/gif/crm.png" width="100px" alt="" />
                                    </div>
                                    <span className="left-line-3"></span>
                                    <h6>CRM</h6>
                                </div>
                                <div className="left-erp-4">
                                    <div className="left-erp-bx">
                                        <img src="assets/Images/gif/sales.png" width="100px" alt="" />
                                    </div>
                                    <span className="left-line-4"></span>
                                    <h6>Sales</h6>
                                </div>
                                {/* <span className="cpu-line-top-2"></span>
                            <span className="cpu-line-top-3"></span>
                            <span className="cpu-line-top-4"></span> */}




                            </div>
                            <div className="right-erp-circle ">

                                <div className="right-erp-1">
                                    <div className="left-erp-bx text-center mx-auto">
                                        <img src="assets/Images/gif/supply-chain.png" width="100px" alt="" />
                                    </div>
                                    <span className="right-line-1"></span>
                                    <h6>Supply Management <br /> Chain</h6>
                                </div>
                                <div className="right-erp-2">
                                    <div className="left-erp-bx">
                                        <img src="assets/Images/gif/database.png" width="100px" alt="" />
                                    </div>
                                    <span className="right-line-2"></span>
                                    <h6>Data Service</h6>
                                </div>
                                <div className="right-erp-3">
                                    <div className="left-erp-bx">
                                        <img src="assets/Images/gif/mrp.png" width="100px" alt="" />
                                    </div>
                                    <span className="right-line-3"></span>
                                    <h6>MRP</h6>
                                </div>
                                <div className="right-erp-4">
                                    <div className="left-erp-bx mx-auto">
                                        <img src="assets/Images/gif/deadline.png" width="100px" alt="" />
                                    </div>
                                    <span className="right-line-4"></span>
                                    <h6>Project <br /> Management</h6>
                                </div>





                            </div>
                            <div className="top-erp-circle ">

                                <div className="top-erp-1">
                                    <div className="top-erp-bx text-center mx-auto">
                                        <img src="assets/Images/gif/production.png" width="100px" alt="" />


                                    </div>
                                    <span className="top-line-1"></span>
                                    <h6>Production</h6>
                                </div>
                                <div className="top-erp-2">
                                    <div className="top-erp-bx">
                                        <img src="assets/Images/gif/inventory.png" width="100px" alt="" />
                                    </div>
                                    <span className="top-line-2"></span>
                                    <h6>Inventory</h6>
                                </div>
                                <div className="top-erp-3">
                                    <div className="top-erp-bx">
                                        <img src="assets/Images/gif/service.png" width="80px" alt="" />
                                    </div>
                                    <span className="top-line-3"></span>
                                    <h6>Service</h6>
                                </div>
                                <div className="top-erp-4">
                                    <div className="top-erp-bx mx-auto">
                                        <img src="assets/Images/gif/hr.png" width="100px" alt="" />
                                    </div>
                                    <span className="top-line-4"></span>
                                    <h6>Human Resource</h6>
                                </div>


                            </div>
                            <div className="bottom-erp-circle ">

                                <div className="bottom-erp-1">
                                    <div className="bottom-erp-bx text-center mx-auto">
                                        <img src="assets/Images/gif/sub-contrasting.png" width="100px" alt="" />


                                    </div>
                                    <span className="bottom-line-1"></span>
                                    <h6>Sub Contracting</h6>
                                </div>
                                <div className="bottom-erp-2">
                                    <div className="bottom-erp-bx">
                                        <img src="assets/Images/gif/manufacturing.png" width="100px" alt="" />
                                    </div>
                                    <span className="bottom-line-2"></span>
                                    <h6>Manufacturing</h6>
                                </div>
                                <div className="bottom-erp-3">
                                    <div className="bottom-erp-bx">
                                        <img src="assets/Images/gif/point-of-sale.png" width="100px" alt="" />
                                    </div>
                                    <span className="bottom-line-3"></span>
                                    <h6>Point of sale</h6>
                                </div>
                                <div className="bottom-erp-4">
                                    <div className="bottom-erp-bx mx-auto">
                                        <img src="assets/Images/gif/purchase.png" width="100px" alt="" />
                                    </div>
                                    <span className="bottom-line-4"></span>
                                    <h6>Purchase</h6>
                                </div>


                            </div>
                        </div>
                        <div className="erp-box">

                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default HomeMain1