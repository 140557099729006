import React, { useEffect, useState } from 'react';
// import S from './pStyle.module.css';
import photo from './CrmImg/photo.jpg';
import { computeHeadingLevel } from '@testing-library/react';
// import page2 from './page2';
// import Left from './menu-dropdown'
// import Header from './header'

// ................

// ................



function Portfolio({ index, mainHead, items, num }) {
    const [check, setCheck] = useState(window.innerWidth < 720);
    useEffect(() => {
        const handleResize = () => {
            setCheck(window.innerWidth < 720);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);
    return (
        <div className='p-body' style={{ height: '100%', overflowY: '' }}>
            {/* <Header/> */}

            <div className='p-main'>
                {/* <Left initialActiveLinkOne="Home Page" initialActiveLinkTwo="" initialActiveLinkThree="" /> */}
                {/* <div className='img-container' style={{ display: 'flex', justifyContent: 'center', margin: 'auto' }}> */}
                {/* <div className='img-container' >
                    <img className='p-img' src={items.img} alt="" style={{ height: '20rem', marginLeft: '8rem' }} />
                </div>
                <section class="section-2">
                    <div class="line">
                        <div className="p-circle">{!check ? index : num}</div>
                        <div className="line-title">{!check ? items.subHeading : mainHead }</div>
                        <div className='hr-line' style={{ width: '9rem' }}></div>
                    </div>
                    <div className="info">
                        {check && <h5 style={{ color: '#d04319' }}>{items.subHeading}</h5>}
                        <img className='p-img responsive-img' src={items.img} alt="" style={{ height: '20rem', marginLeft: '8rem' }} />
                        <ul>
                            {items.pointsArr.length === 0 && <li ><a href="#">No points Yet</a></li>}
                           {items.pointsArr.map((data,i)=> <li key={i}><a href="#">{data}</a></li>)}
                        </ul>
                    </div> */}
                {/* <div class="line">
                <div class="circle">3</div>
                <div class="line-title">SERVISES</div>
                <hr/>
            </div> */}
                {/* </section> */}
                <div className='pageSection'>
                    <div className="imgContainer">
                        <img src={items.img} alt="" />
                    </div>
                    <div className="dataContainer">
                        <div class="line">
                            <div className="p-circle">{!check ? index : (num || 1)}</div>
                            <div className="line-title">{!check ? items.subHeading : mainHead}</div>
                            <div className='hr-line'></div>
                        </div>
                        {check && <h5 >{items.subHeading}</h5>}
                        <div className="info">
                            <div className="imgContainerTwo">
                                <img src={items.img} alt="" />
                            </div>
                            <ul>
                                {items.pointsArr.length === 0 && <li ><a href="#">No points Yet</a></li>}
                                {items.pointsArr.map((data, i) => <li key={i}><a href="#">{data}</a></li>)}
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="scroll-bar"><div class="scroll-circle"></div></div>
            </div>
        </div>
    );
}

export default Portfolio;
