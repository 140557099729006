import React from 'react'
import Footer from '../main-components/Footer';
import Header from '../main-components/Header';
import IndustriesBanner from './sub-components/Industries/IndustriesBanner';
import IndustriesMain from './sub-components/Industries/IndustriesMain';




const Industries = () => {
  return (
    <>
    <Header/>
    <IndustriesBanner/>
    <IndustriesMain/>
    <Footer/>
    </>
  )
}

export default Industries