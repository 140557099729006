import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import About from '../page-components/About';
import AppFrame from '../page-components/AppFrame';
import Consulting from '../page-components/Consulting';
import Contact from '../page-components/Contact';
import Digital from '../page-components/Digital';


import Home from '../page-components/Home';
import Industries from '../page-components/Industries';
import Inside from '../page-components/Inside';
import Saas from '../page-components/Saas';
import WebFrame from '../page-components/WebFrame';
import ScrollToTop from './ScrollToTop';
import Crm from '../page-components/Crm';

const RouterPage = () => {
  return (
    <>
    <Router>
      <ScrollToTop/>
            <Routes>
                <Route path='/' exact element={<Home/>}></Route>
                <Route path='/about' exact element={<About/>}></Route>
                <Route path='/contact' exact element={<Contact/>}></Route>
                <Route path='/digital-marketing' exact element={<Digital/>}></Route>
                <Route path='/consulting-services' exact element={<Consulting/>}></Route>
                <Route path='/application-development' exact element={<AppFrame/>}></Route>
                <Route path='/industries' exact element={<Industries/>}></Route>
                <Route path='/website-development' exact element={<WebFrame/>}></Route>
                <Route path='/saas-services' exact element={<Saas/>}></Route>
                <Route path='/crm' exact element={<Crm/>}></Route>
                <Route path='/inside' exact element={<Inside/>}></Route>
                {/* <Route path='/project-detail' exact element={<Project_detail/>}></Route> */}
                </Routes>
        </Router>
    </>
  )
}

export default RouterPage